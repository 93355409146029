<template color="tapiz" class="mx-1">

<v-row class="mx-1 mt-1" color="tapiz">

<!-- $notify --->
  <notifications group="app" position="top left"  classes="my-notification"/>



<v-col cols="12" class="pa-0">

<!--- diseño para cualquier pantalla-->
<v-row  class="pa-0 d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex">

  <v-col cols="8" xs="8" sm="5"  md="5"  lg="5" xl="5" class="mt-2">

    <v-icon slot="icon" color="red" size="25" class="mx-2">flag</v-icon>
    <span class="text-subtitle-2 pt-3 mt-3">Vista de administracion vehiculos</span>
       

  </v-col>
         
 <v-col cols="4" xs="4" sm="7" md="7" lg="7" xl="7" class="float-right text-lg-right text-md-right text-right">
   
       <v-flex class="d-inline-flex pb-0 pt-0">

        <v-switch class="d-inline-flex mt-1 mr-4"
                x-small
                v-model="estado_switch"
                color="primary"
                hide-details
                inset
                :label="`${switch_ver}`"
                @change="setearDatosSelect()"
              ></v-switch>



           <v-select v-model="id_pais_activo" :items="PaisesArray" @change="ListarFlotas()" small  style="width:150px;height:30px;padding-top:0px;"
            label="">
          </v-select>

        </v-flex>


         <v-flex class="d-inline-flex pb-0 pt-0 mx-2">

           <v-select v-model="id_flota_vista" :items="SelectFlotas"  @change="CambiarSwitch()" small  style="width:200px;height:30px;padding-top:0px;"
            label="">
          </v-select>

        </v-flex>
   


      <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon v-bind="attrs"  v-on="on" small color="primary" @click="dialogInicio=true">
            <v-icon dark>
            today
            </v-icon>
          </v-btn>

          </template>
      <span>Cambio fechas</span>
      </v-tooltip>


     
     
     

       <v-btn color="info" text outlined  @click="CambiarVistaPais()" small><v-icon>flag</v-icon>Paises</v-btn>

  
      

        <v-tooltip bottom class="float-right">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
                  <v-icon dark>logout</v-icon>
                </v-btn>
              </template>
              <span>Salir de aplicacion</span>
          </v-tooltip>

    

  </v-col>

</v-row>



<!--- diseño para movil-->
<v-row class="pa-0 d-inline  d-sm-none d-lg-none d-xl-none d-md-none">

  <v-col cols="12" class="pl-0 pr-0">

    <v-icon slot="icon" color="red" size="25" class="mx-1">flag</v-icon>
    <span class="text-subtitle-2 pt-3 mt-3 mx-1">Pais</span>


      <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon v-bind="attrs"  v-on="on" small color="primary" @click="dialogInicio=true">
            <v-icon dark>
            today
            </v-icon>
          </v-btn>

          </template>
      <span>Cambio fechas</span>
      </v-tooltip>


        <v-btn color="info" text outlined  @click="CambiarVistaPais()" small><v-icon>flag</v-icon>Paises</v-btn>


        <v-tooltip bottom class="float-right">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
                  <v-icon dark>logout</v-icon>
                </v-btn>
              </template>
              <span>Salir de aplicacion</span>
          </v-tooltip>


      
       
        <v-select v-model="id_pais_activo" :items="PaisesArray" small  outlined   label="">
        </v-select>



     

    

  </v-col>

</v-row>


</v-col>


 <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
  </v-dialog>


  <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Dashboard por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="Procesar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>

     













<!--- datos de dashboard  --->

   <v-col xs="10" sm="2" md="1" lg="1" xl="1"> 
 
    <v-card class="pr-0 pl-0 pt-0 pb-0 d-flex"  style="background-color:#9155FD"  @click="dialogVehiculos=true"> 

      <v-list-item-group class="text--center text-caption pl-0 pr-0 pt-1 pb-0" @click="dialogVehiculos=true">
        <v-list-item class="text--center pl-2 pr-0 pt-0 pb-0">
         <v-list-item-content class="text--center pt-0 pb-0">
        <v-list-item-title class="white--text text-h7 pr-1 pt-0">{{ numeroVehiculos }} <v-icon dark small>time_to_leave</v-icon>
        </v-list-item-title>
        <v-list-item-subtitle class="white--text text--center text-caption pr-0 pl-0 pt-0">vehiculos</v-list-item-subtitle>

        
        </v-list-item-content>
        </v-list-item> 
      </v-list-item-group>  

  <v-spacer></v-spacer>
      <v-chip color="white" text-color="indigo" small  class="text--center d-inline-flex d-ms-none d-md-none d-lg-none d-xl-none  mt-4 float-right">
           <v-icon right small class="mx-4">visibility</v-icon>
            Detalle
        </v-chip> 
      
    </v-card> 








     <v-card style="background-color:#0d6efd" class="my-3 pl-0 pr-0 pt-0 pb-0 d-flex" @click="dialogConductores=true"> 

      <v-list-item-group class="text--center text-caption pl-0 pr-0 pt-1 pb-0" @click="dialogConductores=true">
        <v-list-item class="text--center pl-0 pr-0 pt-0 pb-0">
         <v-list-item-content class="text--center pt-0 pl-2 pr-0 pb-0">
        <v-list-item-title class="white--text text-h7">{{ numeroConductores }} <v-icon dark small>person</v-icon>
        </v-list-item-title>
        <v-list-item-subtitle class="white--text text--center text-caption pr-0 pl-0">Conductores</v-list-item-subtitle>

       
        </v-list-item-content>
        </v-list-item> 
      </v-list-item-group>  

       <v-spacer></v-spacer>
        <v-chip color="white" text-color="indigo" small class="text--center d-inline-flex d-ms-none d-md-none d-lg-none d-xl-none  mt-4 float-right mx-1">
           <v-icon right class="mx-4">visibility</v-icon>
            Detalle
        </v-chip> 
    </v-card> 






      <v-card style="background-color:#FF4C51" class="my-3 pl-0 pr-0 pt-0 pb-0 d-flex" @click="dialogExceso=true"> 

      <v-list-item-group class="text--center text-caption pl-0 pr-0 pt-1 pb-0" @click="dialogExceso=true">
        <v-list-item class="text--center pl-0 pr-0">
         <v-list-item-content class="text--center pl-2 pr-0 pt-0 pb-0">
        <v-list-item-title class="white--text text-h8">{{ numeroExcesos }} <v-icon dark small>warning_amber</v-icon>
        </v-list-item-title>
        <v-list-item-subtitle class="white--text text--center text-caption pr-0 pl-0">Exceso velocidad</v-list-item-subtitle>

        </v-list-item-content>
        </v-list-item> 
      </v-list-item-group>  

  <v-spacer></v-spacer>
       <v-chip color="white" text-color="indigo" small  class="text--center d-inline-flex d-ms-none d-md-none d-lg-none d-xl-none  mt-4 float-right mx-1">
           <v-icon right small class="mx-4">visibility</v-icon>
            Detalle
        </v-chip> 

    </v-card>






   <v-card style="background-color:#16B1FF" class="my-3 pl-0 pr-0 pt-0  d-flex" @click="dialogParadasNo=true"> 

      <v-list-item-group class="text--center pl-0 pr-0 pt-1 pb-0" @click="dialogParadasNo=true">
        <v-list-item class="text--center  pl-0 pr-0">
         <v-list-item-content class="text--center  pl-2 pr-0 pt-0 pb-0">
        <v-list-item-title class="white--text text-h8">{{ totalParadasNo }} <v-icon dark small>wrong_location</v-icon>
        </v-list-item-title>
        <v-list-item-subtitle class="white--text text--center text-caption pr-0 pl-0">Paradas No permitidas</v-list-item-subtitle>

       

        </v-list-item-content>
        </v-list-item> 
      </v-list-item-group>  
       <v-spacer></v-spacer>

       <v-chip color="white" text-color="indigo" small class="d-inline-flex d-ms-none d-md-none d-lg-none d-xl-none  mt-4 float-right mx-1">
           <v-icon right class="mx-4">visibility</v-icon>
            Detalle
        </v-chip> 

    </v-card> 



     <v-card style="background-color:#56CA00" class="my-3 pl-0 pr-0 pt-0 d-flex" @click="dialogMantto=true"> 

      <v-list-item-group class="text--center pl-0 pr-0 pt-1 pb-0" @click="dialogMantto=true">
        <v-list-item class="text--center  pl-0 pr-0">
         <v-list-item-content class="text--center  pl-2 pr-0 pt-0 pb-0">
        <v-list-item-title class="white--text text-h8">{{ numeroMattos }} <v-icon dark small>car_repair</v-icon>
        </v-list-item-title>
        <v-list-item-subtitle class="white--text text--center text-caption pr-0 pl-0">Mantenimientos</v-list-item-subtitle>

       

        </v-list-item-content>
        </v-list-item> 
      </v-list-item-group> 

      <v-spacer></v-spacer>
       <v-chip color="white" text-color="indigo" small class=" text--center d-inline-flex d-ms-none d-md-none d-lg-none d-xl-none  mt-4 mx-1">
           <v-icon right class="mx-4">visibility</v-icon>
            Detalle
        </v-chip> 

    </v-card> 

   </v-col>




   
 <v-dialog v-model="dialogVehiculos" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de vehiculos
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogVehiculos=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>


               
                  <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchVeh"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center">
                            <span class="h5 align-center">Datos de vehiculos</span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersVehiculos"
                            :items="Arrayflota"
                            :items-per-page="5"
                            :search="searchVeh"
                            class="elevation-1">


                             <template v-slot:[`item.icono`]="{ item }">
                                <img  :src="CambiarImgUrl(item.icono)" v-bind:alt="item.icono"   style="width: 30px; height: 30px"/>
                            </template>

                            <template v-slot:[`item.id_conductor`]="{ item }">
                              <span>{{item.id_conductor.nombre_conductor}}</span>
                            </template>


                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogVehiculos=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>

   <v-dialog v-model="dialogConductores" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Conductores
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogConductores=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>


               
                  <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchCond"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center">
                            <span class="h5 align-center">Datos de vehiculos</span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersConductores"
                            :items="ConductoresArray"
                            :items-per-page="5"
                            :search="searchCond"
                            class="elevation-1">


                           

                            <template v-slot:[`item.id_conductor`]="{ item }">
                              <span>{{item.id_conductor.nombre_conductor}}</span>
                            </template>

                                <template v-slot:[`item.activo`]="{ item }">
                                    <v-icon>
                                      {{ item.activo==true ? "done" : "label_off" }}
                                    </v-icon>
                              </template>

                               <template v-slot:[`item.expiracion`]="{ item }">
                                <span>{{ getDate(item.expiracion) }}</span>
                                <v-icon color="red">{{ evaluarVencimineto(item.expiracion)}}</v-icon>
                          </template>


                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogConductores=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>

  <v-dialog v-model="dialogParadasNo" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de paradas No permitidas
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogParadasNo=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchPaNo"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de paradas No permitidas</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersParadasNo"
                            :items="itemsParadasNo"
                            :items-per-page="5"
                            :search="searchPaNo"
                            class="elevation-1">

                                <template v-slot:[`item.fecha`]="{ item }"> 
                                <span>{{getDate(item.fecha)}} </span>
                                </template>


                                  <template v-slot:[`item.autorizado`]="{ item }">
                                      <v-icon>
                                        {{ item.autorizado==true ? "done" : "label_off" }}
                                      </v-icon>
                                      </template>


                                    <template v-slot:[`item.ubicacion`]="{ item }">
                                      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                      </template>
                        
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogParadasNo=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>



    <!--- Excesos de velocidad  --->

     <v-col xs="12" sm="12" md="6" lg="6" xl="6">

              <v-card  elevation="4">

                <v-card-title>Excesos de velocidad
                  <v-chip color="info" small  class="mx-1">{{ numeroExcesos }}</v-chip>
                  
                
                    <v-btn  color="primary" small @click="dialogExceso=true"  class="mx-2" rounded>Detalle</v-btn>
                </v-card-title>

                <v-card-text class="pt-0">
               
                      <v-row class="pa-0 pb-0">

                        <v-col cols="4" v-for="(itemseveridad, index) in CategoriaGlobalExces" :key="`severidad-${index}`" class="pa-0 pb-0 pr-0">

                           <v-list-item class="pb-0 pt-0 pr-0">
                           <v-list-item-avatar class="pb-0 pt-0 pr-0">
                               
                            <v-avatar size="20" :color="itemseveridad.color" rounded  class="elevation-1">
                                  <v-icon dark color="white"  small>{{ itemseveridad.icono }}</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content class="pb-0 pt-0 pl-0">

                                <v-list-item-title class="caption">{{ itemseveridad.nombre }}</v-list-item-title>
                                <v-list-item-subtitle class="caption">{{ itemseveridad.valor }}</v-list-item-subtitle>
                            </v-list-item-content>

                          </v-list-item>

                        </v-col>

                         <div id="chart" class="pt-0 pb-0 mt-0" style="margin-top:-23px">
                            <apexchart type="bar"  height="240" :options="chartOptionsExcesos" :series="seriesExcesos" style="margin-top:-23px"></apexchart>
                        </div> 


                      </v-row>
 
                </v-card-text>


              </v-card>


     </v-col>


     <!---Dialogo Excesos de velocidad-->
   <v-dialog v-model="dialogExceso" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Excesos de velocidad
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogExceso=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchExcesos"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Excesos de velocidad</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersExcesos"
                            :items="itemsExceso"
                            :items-per-page="5"
                            :search="searchExcesos"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{getDate(item.fecha)}} </span>
                              </template>


                              <template v-slot:[`item.ubicacion`]="{ item }">
                                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                              </template>


                            
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogExceso=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>








<!--- Paradas de la  operacion -->

    <v-col xs="12" sm="10" md="5" lg="5" xl="5">
         <v-card class="mb-0">
    <v-card-title class="align-start">
      <span>Parada Detectadas</span>


      <v-btn class="ml-2" rounded small color="indigo" dark>
       <v-icon dark color="white">where_to_vote</v-icon>
        {{ totalParadasAuto }}
     </v-btn>

      <v-btn class="ml-2" rounded small color="verde" dark>
       <v-icon dark color="white">wrong_location</v-icon>
        {{ totalParadasNo }}
     </v-btn>


   

      <v-btn color="primary" class="ml-3" outlined small  @click="dialogOperacion=true">Detalle</v-btn>
  
      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">local_shipping</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-0 pa-0">
     
      <apexchart class="mb-n3"
        :options="chartOptionsDataAuto"
        :series="seriesData"
        height="260"
      ></apexchart>

  
     
    </v-card-text>
  </v-card>
    </v-col>



  <!---Dialogo Operacion-->
      <v-dialog v-model="dialogOperacion" max-width="800px">
              <v-card>

                  <v-toolbar color="fondo1">
                  <v-toolbar-title class="white--text">
                  Detalle de Operacion
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon dark @click="dialogOperacion=false">close</v-icon>
                </v-toolbar>



                <v-card-text>
                  <v-container>
                    <v-row>
                  
                    
                  <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchOperacion"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                      

                  

                        <v-col cols="12" sm="12" md="12">

                            <div class="align-center text-md-center mb-2">
                                <span class="h5 align-center"><strong>Datos de Operacion</strong></span>
                            </div>
                            <v-divider></v-divider>
                            
                                <v-data-table
                                :headers="headersOperacion"
                                :items="itemsOperacion"
                                :items-per-page="5"
                                :search="searchOperacion"
                                class="elevation-1">

                                  <template v-slot:[`item.fecha`]="{ item }"> 
                                  <span>{{ ConvertirDia(item.fecha) }} </span>
                                  </template>

            
                            </v-data-table>

                                      

                        </v-col>


                    </v-row>
                    
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogOperacion=false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
      </v-dialog>










  <!--- Graficas de ralenti  --->

    <v-col xs="12" sm="12" md="5" lg="5" xl="5" class="mt-0 pt-0 pb-md-n3">
      <v-card  elevation="4" class="mt-0 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span>Ralenti</span> 
        <v-btn color="secondary" class="ml-7 pb-md-0"  dark small @click="dialogRalenti=true">Detalle</v-btn>
        <v-spacer></v-spacer><v-icon>timer</v-icon></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar" :height="heightRalenti" :options="chartOptionsRalenti" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesRalenti"></apexchart>
            </div>
            </v-card-text>
            </v-card>
            </v-col>
          
          
<!---Dialogo ralenti-->
 <v-dialog v-model="dialogRalenti" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Ralenti
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogRalenti=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               
                      <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchRalenti"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Ralenti</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersRalenti"
                            :items="itemsRalenti"
                            :items-per-page="5"
                            :search="searchRalenti"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{ ConvertirDia(item.fecha)}} </span>
                              </template>


                              <template v-slot:[`item.ubicacion`]="{ item }">
                                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                </template>
                        
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogRalenti=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>





    <!--- Combustible -->

         <v-col xs="12" sm="12" md="7" lg="7" xl="7" class="pt-md-0 mt-md-0 pb-n5" style="padding-bottom:-30px;">

         <v-card elevation="4" class="pt-0 pb-n5" style="padding-bottom:-30px;">

            <v-card-title>
              Rendimiento combustible

              <v-btn color="warning" class="ml-5" small @click="dialogCombustible=true">Ver detalle</v-btn>
              <v-spacer></v-spacer>
               <v-icon>local_gas_station</v-icon>
            </v-card-title>
             <v-card-text class="pa-0">


                <v-row class="mt-0 pt-0 mx-1 my-1">
                  <v-col cols="3" class="pt-0 mt-0">
                     <v-avatar size="20" color="indigo" rounded  class="elevation-1">
                    <v-icon dark color="white" small>
                      straighten
                    </v-icon>
                  </v-avatar>
                  <span class="mx-1"> {{ distancia_global }} km </span>

                  </v-col>


                  <!--v-col cols="3" class="pt-0 mt-0">
                      <v-avatar size="20" color="accent" rounded  class="elevation-1">
                      <v-icon dark color="white" small>
                        local_gas_station
                      </v-icon>
                    </v-avatar>
                    <span class="mx-1">{{ galones_aprox_global }} GL Aprox.</span>
                  </v-col-->


                    <v-col cols="3" class="pt-0 mt-0">
                       <v-avatar size="20" color="secondary" rounded  class="elevation-1">
                        <v-icon dark color="white" small>
                        local_gas_station
                        </v-icon>
                      </v-avatar>
                      <span class="mx-1">{{ galones_asignados_global }} GL </span>
                    
                    </v-col>




                     <!--v-col cols="3" class="pt-0 mt-0">
                        <v-avatar size="20" color="success" rounded  class="elevation-1">
                        <v-icon dark color="white" small>
                        paid
                        </v-icon>
                      </v-avatar>
                      <span class="mx-1"> ${{ gasto_global }} gasto</span>
                     </v-col--->

                     <v-col cols="3" class="pt-0 mt-0">
                        <v-avatar size="20" color="success" rounded  class="elevation-1">
                        <v-icon dark color="white" small>
                        trending_up
                        </v-icon>
                      </v-avatar>
                      <span class="mx-1"> %{{ eficiencia_global }} eficiencia</span>
                     </v-col>


                    
                </v-row>
                  <div id="chart" class="mt-1">
                        <apexchart type="bar" height="230" :options="chartOptionsCombustible"  :series="seriesCombustible"></apexchart>
                   </div>
                 
             </v-card-text>
         </v-card>

         </v-col>



          <!---Dialogo combustible-->
   <v-dialog v-model="dialogCombustible" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de combustible
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogCombustible=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                      <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchCombustible"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>



      
              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Combustible</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersCombustible"
                            :items="itemsCombustible"
                            :items-per-page="5"
                            :search="searchCombustible"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{ConvertirDia(item.fecha)}} </span>
                              </template>

        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCombustible=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
   </v-dialog>




<!--  seccion manteniminetos-->
<v-col xs="12" sm="12" md="7" lg="7" xl="7">
  <v-card>
      <v-card-title>

        <span>Detalle Mantenimientos</span>
        
         <v-chip color="info" small  class="mx-2">{{ numeroMattos }}</v-chip>
                  <v-spacer></v-spacer>
                  
                    <v-btn  color="primary" small @click="dialogMantto=true"  rounded>Detalle</v-btn>

      </v-card-title>
      
      <v-card-text class="mt-0 pt-0">

          <v-row>
            <v-col cols="6" md="3" sm="4" class="d-flex align-center">
              <v-avatar size="25" color="indigo"  rounded class="elevation-1">
                <v-icon dark color="white" small>water_drop</v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0 text-caption">
                  Matto Aceite
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ numeroMattoAceite }}
                </h3>
              </div>
            </v-col>


             <v-col cols="6" md="3" sm="4" class="d-flex align-center">
              <v-avatar size="25" color="primary"  rounded class="elevation-1">
                <v-icon dark color="white" small>disc_full</v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0 text-caption">
                  Matto Llantas
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ numeroMattoLlantas }}
                </h3>
              </div>
            </v-col>


             <v-col cols="6"  sm="4" md="3" class="d-flex align-center">
              <v-avatar size="25" color="red"  rounded class="elevation-1">
                <v-icon dark color="white" small>build</v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0 text-caption">
                  Matto Frenos
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ numeroMattoFreno }}
                </h3>
              </div>
            </v-col>
      </v-row>


      </v-card-text>
    

  </v-card>
</v-col>



 <!---Dialogo Mantto-->
      <v-dialog v-model="dialogMantto" max-width="850px">
              <v-card>

                  <v-toolbar color="fondo1">
                  <v-toolbar-title class="white--text">
                  Detalle de Mantenimientos pendientes
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon dark @click="dialogMantto=false">close</v-icon>
                </v-toolbar>



                <v-card-text>

                    <v-spacer></v-spacer>

               <v-text-field class="mx-5"
                  v-model="searchMantto"
                  append-icon="search"
                  label="Busqueda"
                  single-line
                  hide-details
                ></v-text-field>



                  <v-data-table
          :headers="headersMantto"
          :items="ArregloMantenimientos"
          :search="search"
          sort-by="calories"
          class="elevation-1 mt-5"
        >


    <template v-slot:[`item.id_vehiculo_nombre`]="{ item }">
       <span>{{item.id_vehiculo_mant.nombre}}</span>
       <img  :src="CambiarImgUrl(item.id_vehiculo_mant.icono)" v-bind:alt="item.icono" 
         style="width: 30px; height: 30px"/>
     </template>


      <template v-slot:[`item.placa`]="{ item }">
       <span>{{item.id_vehiculo_mant.placa}}</span>
     </template>

    
      <template v-slot:[`item.id_vehiculo_flota`]="{ item }"> 
       <span>{{item.id_vehiculo_mant.flota}}</span>
     </template>


     <template v-slot:[`item.id_tipo_mant`]="{ item }"> 
       <span>{{item.id_tipo_mant.nombre_tipo}} / {{item.id_tipo_mant.cantidad_km}} km</span>
     </template>


     <template v-slot:[`item.fecha_anterior_mant`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha_anterior_mant)}} </span>
     </template>


     <template v-slot:[`item.status`]="{ item }"> 
       <v-btn v-if="item.status==1" color="info" small>Activo 
         <v-icon small>assignment_turned_in</v-icon></v-btn>
     </template>


     <template v-slot:[`item.remanente`]="{ item }"> 
      <v-chip v-show=" item.remanente < 0" class="ma-2 white--text" color="blue" @click="abrirDetalle(item)">
         {{item.remanente}}
    </v-chip>

     <v-chip v-show="item.remanente > 0" class="ma-2" color="red"  @click="abrirDetalle(item)">
         {{item.remanente}}
    </v-chip>

     </template>


     </v-data-table>
                 
                </v-card-text>                       

              </v-card>
      </v-dialog>

<!--  seccion botones-->
<v-col xs="12" sm="12" md="5" lg="5" xl="5">
  <v-card>
      <v-card-title>

        <span>Botones panico</span>
        
         <v-chip color="red" small  dark class="mx-2">{{ numeroBotones }}</v-chip>
                  <v-spacer></v-spacer>
                  
                    <v-btn  color="red" dark small @click="dialogBotones=true"  rounded>Detalle</v-btn>

      </v-card-title>
      
      <v-card-text class="mt-0 pt-0">

          <v-row>
            <v-col cols="6" md="6" class="d-flex align-center">
              <v-avatar size="25" color="red"  rounded class="elevation-1">
                <v-icon dark color="white" small>campaign</v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0 text-caption">
                  Botones total
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ numeroBotones }}
                </h3>
              </div>
            </v-col>


             <v-col cols="6" md="6" class="d-flex align-center">
              <v-avatar size="25" color="success"  rounded class="elevation-1">
                <v-icon dark small>notification_important</v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0 text-caption">
                  Botones Verificados
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ numeroBotonesEditados }}
                </h3>
              </div>
            </v-col>


           
      </v-row>


      </v-card-text>
    

  </v-card>
</v-col>



 
    <!--- Edicion botones de panico--->
    <v-dialog   v-model="dialog"  max-width="700px">

          <v-card>


            <v-toolbar color="fondo1">
            <v-toolbar-title class="white--text">{{ editedItem.evento }}: {{ editedItem.nombre }}  </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>


            <v-card-text>
              <v-container>

                <v-row>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Vehiculo"
                    v-model="editedItem.nombre"
                    disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Placa"
                    v-model="editedItem.placa"
                    disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Evento"
                     v-model="editedItem.evento"
                     disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Fecha"
                    v-model="editedItem.fecha"
                    disabled
                 ></v-text-field>
                </v-col>





                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      background-color="grey lighten-2"
                      prepend-icon="description"
                      v-model="editedItem.observacion"
                      label="Observacion"
                    placeholder="aqui ingrese observacion"
                      rows="2"
                    ></v-textarea>
                  </v-col>



                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
            
              
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="primary" dark @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


 <!---Dialogo botones-->
   <v-dialog v-model="dialogBotones" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de botones de panico validos
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogBotones=false">close</v-icon>
            </v-toolbar>

            <v-card-text>


                <v-text-field class="mx-5"
                  v-model="search"
                  append-icon="search"
                  label="Busqueda"
                  single-line
                  hide-details
                ></v-text-field>


                 <v-data-table
                  :headers="headers"
                  :items="ArrayBotones"
                  :search="search"
                  sort-by="calories"
                  class="elevation-1 mt-5"
                >


              <template v-slot:[`item.evento`]="{ item }">

                  <v-chip :color="getColor(item)" dark @click="editItemBoton(item)" small >
                    <span>{{ item.evento }}</span>
                  </v-chip>

              </template>

              

                <template v-slot:[`item.fecha`]="{ item }">
                            <span>{{ getDate(item.fecha) }}</span>
                </template>

                <template v-slot:[`item.ubicacion`]="{ item }">
                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
              </template>


              <template v-slot:[`item.observacion`]="{ item }">
                  <v-icon small class="mr-2" color="indigo" @click="editItemBoton(item)">edit</v-icon>
                <span>{{  item.observacion }} </span>
                <span class="caption">- {{ item.editado}}</span>
              </template>

            
              <template v-slot:no-data>
                <v-btn  color="primary" @click="listarBotones()">
                  Reset
                </v-btn>
              </template>
            </v-data-table>



            </v-card-text>
                 

          </v-card>
   </v-dialog>




  <!--- Mnatt pendientes --->
  
    <!--v-col cols="12">

      <v-card>
        <v-card-title>
          <span>Mantenimientos pendientes</span>

          <v-spacer></v-spacer>

            <v-text-field class="mx-5"
                  v-model="searchMantto"
                  append-icon="search"
                  label="Busqueda"
                  single-line
                  hide-details
                ></v-text-field>

        </v-card-title>

        <v-card-text>


          <v-data-table
          :headers="headersMantto"
          :items="ArregloMantenimientos"
          :search="search"
          sort-by="calories"
          class="elevation-1"
        >


    <template v-slot:[`item.id_vehiculo_nombre`]="{ item }">
       <span>{{item.id_vehiculo_mant.nombre}}</span>
       <img  :src="CambiarImgUrl(item.id_vehiculo_mant.icono)" v-bind:alt="item.icono" 
         style="width: 30px; height: 30px"/>
     </template>


      <template v-slot:[`item.placa`]="{ item }">
       <span>{{item.id_vehiculo_mant.placa}}</span>
     </template>

    
      <template v-slot:[`item.id_vehiculo_flota`]="{ item }"> 
       <span>{{item.id_vehiculo_mant.flota}}</span>
     </template>


     <template v-slot:[`item.id_tipo_mant`]="{ item }"> 
       <span>{{item.id_tipo_mant.nombre_tipo}} / {{item.id_tipo_mant.cantidad_km}} km</span>
     </template>


     <template v-slot:[`item.fecha_anterior_mant`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha_anterior_mant)}} </span>
     </template>


     <template v-slot:[`item.status`]="{ item }"> 
       <v-btn v-if="item.status==1" color="info" small>Activo 
         <v-icon small>assignment_turned_in</v-icon></v-btn>
     </template>


     <template v-slot:[`item.remanente`]="{ item }"> 
      <v-chip v-show=" item.remanente < 0" class="ma-2 white--text" color="blue" @click="abrirDetalle(item)">
         {{item.remanente}}
    </v-chip>

     <v-chip v-show="item.remanente > 0" class="ma-2" color="red"  @click="abrirDetalle(item)">
         {{item.remanente}}
    </v-chip>

     </template>


     </v-data-table>

      </v-card-text>
  

    </v-card>
    </v-col-->


        <v-dialog v-model="dialogDetalle" max-width="500px">

        <v-card>

              <v-toolbar dark color="info">
                  <v-toolbar-title>
                    <span class="headline">Detalle mantenimientos</span>
                  </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogDetalle=false" dark>close</v-icon>
                </v-toolbar>

            <v-card-text>

              <v-simple-table light> 
                  <template v-slot:default>
                  <tbody>

                    <tr >
                    <td><v-icon small left>time_to_leave</v-icon>Unidad</td>
                    <td>{{detalle.unidad}}</td>
                  </tr>

                  <tr >
                    <td><v-icon small left>commute</v-icon>Flota</td>
                    <td>{{detalle.flota}}</td>
                  </tr>


                  <tr >
                    <td><v-icon small left>list_alt</v-icon>Tipo mantenimiento</td>
                    <td>{{detalle.tipo_mant}}</td>
                  </tr>


                  <tr >
                    <td><v-icon small left>speed</v-icon>odometro anterior</td>
                    <td>{{detalle.odom_anterior}} <span class="caption indigo--text">{{detalle.tipo_odom}}</span></td>
                  </tr>
                  <tr>
                    <td><v-icon small left>speed</v-icon>odometro actual</td>
                    <td>{{detalle.odom_actual}} <span class="caption indigo--text">{{detalle.tipo_odom}}</span></td>
                  </tr>

                  <tr>
                    <td><v-icon small left>straighten</v-icon>Recorrido</td>
                    <td>{{detalle.recorrido}}</td>
                  </tr>

                  <tr>
                    <td><v-icon small left>flag</v-icon>KM realizar</td>
                    <td>{{detalle.km_realizar}}</td>
                  </tr>


                    <tr>
                    <td> <v-icon small left>forward</v-icon>Remanente</td>
                    <td>{{detalle.remanente}}</td>
                  </tr>

                  <tr>
                    <td>%  Recorrido</td>
                    <td>{{detalle.porcentaje}}</td>
                  </tr>


                </tbody>
                </template>

                </v-simple-table>


            </v-card-text>
          </v-card>

      </v-dialog>


          


       




 </v-row>


    
</template>

<script>

import axios from 'axios';

import { mapState,mapActions } from "vuex";

import { mapMutations } from "vuex";

import VueApexCharts from 'vue-apexcharts';


export default {
  components:{
       apexchart: VueApexCharts
    
    },
     data: () => ({


             id_pais_activo:'',
             PaisesArray:[],
             Arrayflota:[],
             ArrayflotaTrabajo:[],
             ArrayResumen:[],
             configPaises:[],


            dialog:false,

            dialogExit:false,
            dialogInicio:false,


            menuFecha1:false,
            fecha_inicio:new Date().toISOString().substr(0, 10),
            menuFecha2:false,
            fecha_fin:new Date().toISOString().substr(0, 10),
            date: new Date().toISOString().substr(0, 10),


            dialogVehiculos:false,
            searchVeh:'',
            headersVehiculos:[
                { text: 'Icono', value: 'icono' },
                { text: 'IdAvl', value: 'id_avl' },
                { text: 'nombre', value: 'nombre' },
                { text: 'placa', value: 'placa' },
                { text: 'Flota', value: 'flota' },
                { text: 'Conductor', value: 'id_conductor'}
            ],

            numeroVehiculos:0,
            numeroConductores:0,


            dialogConductores:false,
            searchCond:'',

            headersConductores:[  
                 { text: 'Nombre', value: 'nombre_conductor' },
                 { text: 'No.licencia', value: 'no_licencia' },
                 { text: 'Expiracion', value: 'expiracion' },
                 { text: 'Activo', value: 'activo' }
             ],
            ConductoresArray:[],
                        



            //----datos de paradas auto- no auto y distancias

            dialogParadasNo:false,
            searchPaNo:'',
             headersParadasNo:[
                { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Conductor', value: 'nombre_conductor' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Evento', value: 'evento' },
                { text: 'Geocerca', value: 'nombre_geocerca'},
                { text: 'Autorizado', value: 'autorizado'},
                { text: 'Ubicacion', value: 'ubicacion' }
                ],
            itemsParadasNo:[],




            //datos operacion
            dialogOperacion:false,
            searchOperacion:'',
            headersOperacion:[
                { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },  
                { text: 'paradas Autorizadas total', value: 'totalA' },
                { text: 'paradas No Permitidas Total', value: 'totalN' },

                { text: 'Distancia', value: 'distancia' },
                { text: 'hrs ralenti', value: 'tiempo_parado_enc'},
                { text: 'hrs mov', value: 'tiempo_mov' },
                { text: 'Fecha', value: 'fecha' }

            ],
            itemsOperacion:[],







            totalParadasAuto:0,
            totalParadasNo:0,
            total_distancia:0,

            chartOptionsDataAuto:{
                             chart: {
                                  type: 'bar',
                                  height: 260
                                },
                                plotOptions: {
                                  bar: {
                                    horizontal: false,
                                    columnWidth: '55%',
                                    endingShape: 'rounded'
                                  },
                                },
                                dataLabels: {
                                  enabled: false
                                },
                                stroke: {
                                  show: true,
                                  width: 2,
                                  colors: ['transparent']
                                },
                                xaxis: {
                                  categories: [],
                                },
                                yaxis: {
                                  title: {
                                    text: ''
                                  }
                                },
                                fill: {
                                  opacity: 1
                                },
                                legend: {
                                            position: 'top'
                                          },
                                tooltip: {
                                  y: {
                                    formatter: function (val) {
                                      return "" + val + " "
                                    }
                                  }
                                }
                       },



               seriesData:[],


                 etiquetas_nombre:[],
                 autorizadas:[],
                  noAuto:[],
                  distancias:[],

          //--------------datos de excesos-------------


          seriesExcesos:[{'name':'', 'data':[]}],


          chartOptionsExcesos:{

                          chart: {
                        type: 'bar',
                        height: 240,
                         width:540,
                        stacked: true,
                        toolbar: {
                              show: false,
                              },
                      },
                        colors: ['#56CA00', '#F44336', '#DFCB5E', '#303C9B', '#FFFD95','#C6D57E','#ED50F1', '#A8E7E9','#57CC99'],
                     
                      plotOptions: {
                        bar: {
                          horizontal: true,
                        },
                      },
                      stroke: {
                        width: 1,
                        colors: ['#fff']
                      },
                      title: {
                        text: ''
                      },
                      xaxis: {
                        categories: [],
                        labels: {
                          formatter: function (val) {
                            return val + ""
                          }
                        }
                      },
                      yaxis: {
                        title: {
                          text: undefined
                        },
                      },
                      tooltip: {
                        y: {
                          formatter: function (val) {
                            return val + ""
                          }
                        }
                      },
                      fill: {
                        opacity: 1
                      },
                      legend: {
                         position: 'bottom',
          horizontalAlign: 'center',
          offsetX: 40
        }
        },
                      

        numeroExcesos:0,
        
        CategoriaGlobalExces:[],
        ArregloExcesos:[],

        vehiculos_excesos:[],
        etiquetas_excesos:[],

        //datos de tablas

        searchExcesos:'',
        headersExcesos:[
         { text: 'Vehiculo', value: 'id_vehiculo.nombre' },
          { text: 'Placa', value: 'id_vehiculo.placa' },
          { text: 'Conductor', value: 'id_conductor.nombre_conductor' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Evento', value: 'evento' },
          { text: 'Velocidad', value: 'velocidad' },
          { text: 'Severidad', value: 'severidad'},
          { text: 'Ubicacion', value: 'ubicacion' },
          { text: 'Direccion', value: 'direccion' },

        ],
        itemsExceso:[],
        dialogExceso:false,




        //---------------Datos de ralenti-----------------------
              heightRalenti:310,


            dialogRalenti:false,
            searchRalenti:'',
            headersRalenti:[ { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Distancia', value: 'distancia' },
                { text: 'hrs ralenti', value: 'tiempo_parado_enc'},
                { text: 'hrs mov', value: 'tiempo_mov' }],
            itemsRalenti:[],



        chartOptionsRalenti:{
          chart: {
              type: 'bar',
              height:310
            },
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['datos'],
            },
            yaxis: {
              title: {
                text: ''
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return " " + val + ""
                }
              }
            }
          },
        





        seriesRalenti:[{
            name: 'Hrs Ralenti',
            data: []
          }],


          //datos de tabla de botnes

      dialogBotones:false,


      headers: [
      
        { text: 'Vehiculo', value: 'id_vehiculo.nombre' },
        { text: 'Placa', value: 'id_vehiculo.placa' },
        { text: 'Flota', value: 'id_vehiculo.flota' },
        { text: 'Conductor', value:'id_conductor.nombre_conductor'},
        { text: 'Fecha', value: 'fecha' },
        { text:  'Ubicacion', value:  'ubicacion'},
        { text:  'Evento', value:  'evento'},
        { text:  'Direccion', value:  'direccion'},
        { text:  'Observacion', value:  'observacion'}
      ],
      ArrayBotones: [],
      editedIndex: -1,
      editedItem: {
            id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:''
      },
      defaultItem: {
        id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:''
      },

       search:'',


       //---------seccion de combustibles



        ArrayIngresos:[],
        eficiencia_vehiculos:[],
        kmlxgl_vehiculos:[],

        distancia_global:0,
        galones_aprox_global:0,
        galones_asignados_global:0,
        gasto_global:0,
        eficiencia_global:0,

     

        chartOptionsCombustible:{
           chart: {
              type: 'bar',
              height: 230
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              title: {
                text: ''
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return " " + val + ""
                }
              }
            }
        },
        seriesCombustible:[{
            name: '',
            data: []
          }],

        dialogCombustible:false,
        searchCombustible:'',

        headersCombustible:[ 
                { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Distancia', value: 'distancia' },
                { text: 'consumo', value: 'consumo'},
                { text: 'asignacion', value: 'asignacion' },
                { text: 'costo', value: 'costo' }
                ],
        itemsCombustible:[],






          //-----campos para mantenimiento------------------



          searchMantto:'',

          headersMantto:[ 
                          { text: 'Vehiculo', value: 'id_vehiculo_nombre' },
                          { text: 'Placa', value: 'placa' },
                          { text: 'Tipo Mantenimiento', value: 'id_tipo_mant' },
                          { text: 'Fecha Anterior Mant.', value: 'fecha_anterior_mant' },
                          { text: 'Odometro Anterior Mant.', value: 'odometro_anterior_mant' },

                          { text: 'Km realizar.', value: 'kms_realizar'},
                          { text: 'Remanente', value: 'remanente' },
                          { text: 'Estatus', value: 'status' },

                          ],
          ArregloMantenimientos:[],

           dialogDetalle:false,
           detalle:{},

           numeroMattos:0,
          numeroMattoAceite:0,
          numeroMattoLlantas:0,
          numeroMattoFreno:0,

          dialogMantto:false,


          numeroBotones:0,
          numeroBotonesEditados:0,
          rol_actual:'',

         intervalo_ejecucion:null,


         SelectFlotas:[],
         id_flota_vista:'',

         switch_ver:'Flotas',
         estado_switch:true,

        arraySubflotas:[],
        etiquetas_Subflotas:[],
        arrayItemSubflotas:[],

        vehiculos_globales:[],
        arregloFlotas_Globales:[],
        resumenes_globales:[],
        paradas_globales:[],
        mantt_globales:[],



     }),

    computed: {

      ...mapState(['token','usuario']),

       media() {
                return {
                    'is-phone': this.$screen.sm,
                    'is-tablet': this.$screen.md,
                    'is-desktop': this.$screen.lg,
                    'can-touch': this.$screen.touch,
                    'breakpoint': this.$screen.breakpoint,
                };
            },
    

    },

    watch: {

    },
     beforeDestroy () {

      clearInterval(this.intervalo_ejecucion);
      
   },
   created () {


       this.rol_actual=this.usuario.rol;

        this.fecha_inicio=this.CalcularFechaHoy();
        this.fecha_fin=this.CalcularFechaHoy();


         this.listarPaises();


         if(this.media.breakpoint=='sm'){
             this.heightRalenti=310;
             console.log('pantalla es SM');
         }else{
           console.log('pantalla :'+this.media.breakpoint);
         }

       

      
        

    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),

            ...mapActions(['salir']),


            Procesar(){

               this.ListarResumen();

            },

             listarPaises () {

                     

                     
                        this.configPaises=[];

                      

                        let config={headers:{token:this.token}};
                        this.mostrarLoading({titulo:'Accediendo a datos'});

                         let me=this;

                        let RespuestaPaises=[];

                        axios.get('paises',  config
                        ).then(function(response){
                          //console.log(response);
                          RespuestaPaises=response.data;


                          me.configPaises=RespuestaPaises;
                 
                          RespuestaPaises.map(function(x){
                                me.PaisesArray.push({text:x.nombre_pais, value:x._id});
                             });

                             me.id_pais_activo=me.PaisesArray[0].value;


                               me.ocultarLoading();

                             

                                me.ListarFlotas();
                                       
                        }).catch(function(error){
                          console.log(error);
                        });
           },



       
          ListarFlotas(){


              this.estado_switch=true;
              this.switch_ver='Flotas';


            this.arregloFlotas=[];
            this.arregloFlotas_Globales=[];
            this.etiquetas_flotas=[];
           
            this.SelectFlotas=[];

            this.mostrarLoading({titulo:'Accediendo a datos'});
            let config={headers:{token:this.token}};
            let me=this;

             axios.get(`flotas/${this.id_pais_activo}`,  config
                        ).then(function(response){
                        //  console.log(response);
                          me.arregloFlotas=response.data;
                          me.arregloFlotas_Globales=response.data;

                 
                            me.arregloFlotas_Globales.map(function(x){
                              me.etiquetas_flotas.push(x.nombre_flota);
                              me.SelectFlotas.push({text:x.nombre_flota, value:x.nombre_flota});
                            });

                              me.id_flota_vista= me.SelectFlotas[0].value;

                     
                             me.ListarSubFlotas();

                           }).catch(function(error){
                          console.log(error);
                        });



          },

            ListarSubFlotas(){

           //las subflotas solo son traidas no cargadas en el select de Flota mismo

            this.arraySubflotas=[];
            this.etiquetas_Subflotas=[];
            this.arrayItemSubflotas=[];

             this.arrayItemSubflotas.push({text:'Todas', value:1});

             this.mostrarLoading({titulo:'Accediendo a datos'});
            let config={headers:{token:this.token}};
            let me=this;

             axios.get(`subflotas/${this.id_pais_activo}`,
                    config
                    ).then(function(response){

                      //console.log('subflotas');
                     // console.log(response.data);

                      let respuesta=response.data;
                      me.arraySubflotas=respuesta;


                     

                      me.ListarVehiculos();

                   }).catch(function(error){ console.log(error); });

          },

          ListarVehiculos(){

              this.Arrayflota=[];
              this.ArrayflotaTrabajo=[];
              this.numeroVehiculos=0;
              this.vehiculos_globales=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.id_pais_activo}`,
                    config
                    ).then(function(response){
                      //  console.log(response);

                        let respuesta=response.data;

                        me.vehiculos_globales=respuesta;

                        respuesta.map(function(x){

                           if(me.estado_switch==true){  //ver flotas
                                if(x.flota==me.id_flota_vista){  
                                    me.Arrayflota.push(x);
                                }
                           }else{  //ver datos de subflota

                              if((x.id_subflota_veh)&&(x.id_subflota_veh!=null)&&(me.id_flota_vista==x.id_subflota_veh)){
                                 me.Arrayflota.push(x);
                              }
                           }

                        });


                        me.numeroVehiculos= me.Arrayflota.length;
                        me.ocultarLoading();

                          me.ListarResumen();
                          me.listarConductores();


                          //cada 5 min se actualizaran los datos

                            if((me.fecha_inicio==me.CalcularFechaHoy())&&(me.fecha_fin==me.CalcularFechaHoy())){

                                   clearInterval(me.intervalo_ejecucion);

                                  // console.log('se actualziara cada 5 min')

                                  me.intervalo_ejecucion=setInterval(() => {
                                                      me.ListarResumen();
                                                    }, 300000);

                         }else{
                          //  console.log('pagina no se recargara debido a que las fechas no son validas');
                            clearInterval(me.intervalo_ejecucion);
                         }

         

                      
                    }).catch(function(error){ console.log(error); });
           },

         
           


           listarConductores () {

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;

          axios.get(`conductores/${this.id_pais_activo}`, config
          ).then(function(response){
             // console.log(response);
              me.ConductoresArray=response.data;

              me.numeroConductores= me.ConductoresArray.length;
              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

          },



          ListarResumen(){

                //cada 5 min se actualizaran los datos


                            if((this.fecha_inicio==this.CalcularFechaHoy())&&(this.fecha_fin==this.CalcularFechaHoy())){

                                   clearInterval(this.intervalo_ejecucion);

                                  // console.log('se actualziara cada 5 min')

                                  this.intervalo_ejecucion=setInterval(() => {
                                                      this.ListarResumen();
                                                    }, 300000);

                         }else{
                           // console.log('pagina no se recargara debido a que las fechas no son validas');
                            clearInterval(this.intervalo_ejecucion);
                         }
              

             
              this.resumenes_globales=[];
              
              
             



              
                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'accediendo a datos'});
                let me=this;

                axios.post('resumenes',{
                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin,
                      'id_pais':this.id_pais_activo
                }, config
                ).then(function(response){
                  
                  
                  console.log(response.data);
                  me.resumenes_globales=response.data;



                  

                    me.dialogInicio=false;

                       me.listarExcesos();  
                   
                   
                  

                   
                   // me.ocultarLoading();
                }).catch(function(error){
                  console.log(error);
                });


          },

     listarExcesos(){


               this.ArregloExcesos=[];
               let me=this;

                let config={headers:{ token:this.token}};
               
                me.mostrarLoading({titulo:'listando datos'});

                       axios.post('eventos',{ 
                                  'id_pais':this.id_pais_activo,
                                  'desde':this.fecha_inicio,
                                  'hasta':this.fecha_fin,
                                  'tipo':'Exceso'
                              },
                            config
                            ).then(response=>{

                            

                             let respuesta=response.data;

                                respuesta.map(function(x){
                                  if(x.velocidad > 79){
                                    me.ArregloExcesos.push(x);
                                  }
                                });
                       
                                 // me.ocultarLoading();
                                  me.ListarParadas();
                                 
                                 

                         }).catch(e=>{ console.log(e) });



              

                   
                   
            },

          ListarParadas(){

                this.paradas_globales=[];
               

                let config={headers:{ token:this.token}};
                let me=this;
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('paradas-all',{
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin,
                                'id_parada_pais': this.id_pais_activo
                              },
                            config
                            ).then(response=>{

                           

                              me.paradas_globales=response.data;

                           
                 


                             
                                me.listarBotones();           
                               // me.ocultarLoading();
                                me.dialogInicio=false;
                                

                         }).catch(e=>{ console.log(e) });





           },


        


            
        listarBotones(){

          this.ArrayBotones=[];

          this.numeroBotones=0;
          this.numeroBotonesEditados=0;

           
               let config={headers:{ token:this.token}};
                let me=this;
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('eventos',{ 
                                'id_pais':this.id_pais_activo,
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin,
                                'tipo':'Boton'
                              },
                            config
                            ).then(response=>{
                            
                                   // console.log(response);
                                    me.ArrayBotones=response.data;

                                   

                                     me.ListarIngreso(); 
                                    // me.ocultarLoading();

                                  }).catch(e=>{ console.log(e) });

        },


         ListarIngreso(){

                this.ArrayIngresos=[];
              
              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.post('combustibles-reporte',
                      { 

                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin,
                      'id_pais':this.id_pais_activo
                       },
                  config
                  ).then(function(response){
                    //  console.log(response);
                      me.ArrayIngresos=response.data;

                       me.listarMantt();

                      me.dialogInicio=false;
                  }).catch(function(error){ console.log(error); });

          },



         listarMantt(){


               this.mantt_globales=[];

              this.ArregloMantenimientos=[];

              this.numeroMattos=0;
              this.numeroMattoAceite=0;
              this.numeroMattoLlantas=0;
              this.numeroMattoFreno=0;

              // this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`mantenimientos-pendiente/${this.id_pais_activo}`,
                  config
                  ).then(function(response){
                     // console.log(response);

                      me.mantt_globales=response.data;

  
                     me.CambiarSwitch();


                      me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },


         abrirDetalle(item){

        this.detalle={};
        this.dialogDetalle=true;

        let odom_actual=0;
        let tipo_odom='';

        if(item.id_vehiculo_mant.tipo_odo_activo=='Software'){

             odom_actual=item.id_vehiculo_mant.odometro_sw;
             tipo_odom='Software';

        }else if(item.id_vehiculo_mant.tipo_odo_activo=='Hardware'){

              odom_actual=item.id_vehiculo_mant.odometro_hw;
               tipo_odom='Hardware';
        }


        let recorrido=parseFloat(odom_actual)-parseFloat(item.odometro_anterior_mant);

        let porcentaje=0;

        if(recorrido > 0){

            porcentaje=recorrido/parseFloat(item.kms_realizar);
            porcentaje=porcentaje*100;

        }



        this.detalle={
                      'unidad':item.id_vehiculo_mant.nombre,
                      'flota':item.id_vehiculo_mant.flota,
                      'tipo_mant':item.id_tipo_mant.nombre_tipo,
                      'odom_anterior':item.odometro_anterior_mant,
                      'odom_actual':odom_actual,
                      'tipo_odom':tipo_odom,
                      'recorrido':recorrido,
                      'km_realizar':item.kms_realizar,
                      'remanente':item.remanente,
                      'porcentaje':porcentaje
                      };

      },








          ObtenerDisenoSeveridad(numero){


            let color='primary';
            let icono='error';

            if(numero==0){
              color='primary';
              icono='error';
            }else if(numero==1){
              color='success';
              icono='warning';
            }else if(numero==2){
              color='red';
              icono='bus_alert';
            }else if(numero==3){
              color='yellow';
              icono='report';
            }else{
              color='blue';
              icono='fmd_bad';
            }

          let diseno={'color':color, 'icono':icono};

          return(diseno);


          },

          setearDatosSelect(){

              let me=this;

              me.etiquetas_flotas=[];
              me.SelectFlotas=[];

           

        

            if(me.estado_switch==true){

               this.switch_ver='Flotas';

               me.arregloFlotas=me.arregloFlotas_Globales;

                me.arregloFlotas.map(function(x){
                              me.etiquetas_flotas.push(x.nombre_flota);
                              me.SelectFlotas.push({text:x.nombre_flota, value:x.nombre_flota});
                            });

                              me.id_flota_vista= me.SelectFlotas[0].value;

                              me.CambiarSwitch();
            }else{
                    this.switch_ver='SubFlota';

                     me.arregloFlotas=me.arraySubflotas;

                  me.arraySubflotas.map(function(x){
                              me.etiquetas_flotas.push(x.nombre);
                              me.SelectFlotas.push({text:x.nombre, value:x._id});
                            });

                        if(me.SelectFlotas.length >0){
                            me.id_flota_vista= me.SelectFlotas[0].value;

                             me.CambiarSwitch();
                        }    

                  

            }

           




          },



          //metodo para escojer que ve el usuario si flotas o subflotas
          CambiarSwitch(){

                      


           let me=this;
           



         //cambiamos los camiones de la vista

              me.Arrayflota=[];

                me.vehiculos_globales.map(function(x){

                              if(me.estado_switch==true){  //ver flotas

                                console.log('vef datos por flotas');
                                    if(x.flota==me.id_flota_vista){  
                                        me.Arrayflota.push(x);
                                    }
                              }else{  //ver datos de subflota

                           

                                  if((x.id_subflota_veh)&&(x.id_subflota_veh!=undefined)&&(x.id_subflota_veh!=null)){

                                        if(me.id_flota_vista==x.id_subflota_veh._id){
                                          me.Arrayflota.push(x);

                                         // console.log('vehiculos en subflota:');
                                        //  console.log(x);

                                        }

                                  }
                              }

                            });





            //----------------------------------------------------------------------
            //------------------------RESUMENES-------------------------------------
            //--------------------------------------------------------------------

             this.etiquetas_nombre=[];
             this.ArrayResumen=[];


              me.resumenes_globales.map(function(x){
                  if(me.estado_switch==true){   //aadimos resumenes de la flota seleccionada
                            if((x.id_vehiculo!=null)&&(x.id_vehiculo.flota==me.id_flota_vista)){
                                  me.ArrayResumen.push(x);
                            }

                   }else{
                            //solo de la subflota indicada
                          if((x.id_vehiculo.id_subflota_veh)&&(x.id_vehiculo.id_subflota_veh!=undefined)){
                              if(me.id_flota_vista==x.id_vehiculo.id_subflota_veh){
                                  
                                   me.ArrayResumen.push(x);
                                    //  console.log('vehiculo con resumen')
                                    // console.log(x);
                                  }
                          }
                   }
                     
               });

                   


                    let datos_ralenti=[];
                    me.ArrayflotaTrabajo=[];
                 


                    me.Arrayflota.map(function(y){

                          let total_ralenti=0;
                          let contador_kilometros=0;

                                me.ArrayResumen.map(function(x){

                                    if(x.id_vehiculo._id==y._id){
                                      let tiempo_calculado=me.convertDurationtoSeconds(x.tiempo_parado_enc);
                                      tiempo_calculado=tiempo_calculado/3600;
                                      tiempo_calculado=parseFloat(tiempo_calculado).toFixed(2);

                                      total_ralenti=parseFloat(total_ralenti)+parseFloat(tiempo_calculado);
                                      total_ralenti=parseFloat(total_ralenti).toFixed(2);

                                      contador_kilometros=parseFloat(contador_kilometros)+parseFloat(x.distancia);
                                      }//si es mimso vehiculo
                                });


                       if(contador_kilometros > 0){

                          me.ArrayflotaTrabajo.push(y);
                          datos_ralenti.push(total_ralenti);

                       }



                    });

                    me.itemsRalenti=[];
                    me.itemsOperacion=[];
                    me.itemsCombustible=[];

                    me.ArrayResumen.map(function(x){

                        let ingrsar=false;
                        if(me.estado_switch==true){   //añadimos paradas de la flota seleccionada
                                              if(x.id_vehiculo.flota==me.id_flota_vista){
                                                  ingrsar=true;
                                              }
                        }else{

                              if((x.id_vehiculo.id_subflota_veh)&&(x.id_vehiculo.id_subflota_veh!=undefined)){
                                  if(me.id_flota_vista==x.id_vehiculo.id_subflota_veh){
                                            ingrsar=true;
                                  }
                                  
                                  }

                        }

                         //verificar si es la flota o subflota indicada
                          if(ingrsar==true){

                                    me.itemsRalenti.push({'vehiculo':x.id_vehiculo.nombre, 'placa':x.id_vehiculo.placa,
                                                        'fecha': x.fecha, 'distancia':x.distancia, 'tiempo_parado_enc':x.tiempo_parado_enc,
                                                        'tiempo_mov':x.tiempo_mov});


                                  me.itemsOperacion.push({'id_ve':x.id_vehiculo._id, 'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                                    'distancia':x.distancia, 'totalA':0, 'totalN':0,
                                                    'tiempo_mov':x.tiempo_mov,'tiempo_parado_enc':x.tiempo_parado_enc, 'fecha':x.fecha });

                                  me.itemsCombustible.push({
                                        'id_ve':x.id_vehiculo._id, 
                                          'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa, 'fecha':x.fecha, 
                                          'distancia':x.distancia,'consumo':x.consumo, 'asignacion':0,
                                          'costo': 0});

                             }

                     });


              

                      me.seriesRalenti=[{
                          name: 'Hrs Ralenti',
                          data: datos_ralenti
                        }];


                        //----------------------------------------------------------------------
                        //------------------------PARADAS-------------------------------------
                        //--------------------------------------------------------------------

                         me.ArregloParadas=[];
                         me.itemsParadasNo=[];
                         me.ArregloParadas=[];
                         me.etiquetas_nombre=[];


                        me.paradas_globales.map(function(x){

                                      if(x.id_vehiculo!=null){
                                        if(me.estado_switch==true){   //añadimos paradas de la flota seleccionada
                                              if(x.id_vehiculo.flota==me.id_flota_vista){
                                                  me.ArregloParadas.push(x);
                                              }

                                        }else{ //añadimos paradas de la Subflota seleccionada

                                            if((x.id_vehiculo.id_subflota_veh)&&(x.id_vehiculo.id_subflota_veh!=undefined)){
                                           
                                              console.log(x.id_vehiculo.id_subflota_veh);
                                              if(me.id_flota_vista==x.id_vehiculo.id_subflota_veh){

                                                     console.log('subflota en parada') 
                                                    me.ArregloParadas.push(x);
                                                }
                                            }

                                        }
                                    }//si el vehiculo tiene id

                                    });

                            
                            
                            let paradas_all=me.ArregloParadas;


                              


                           paradas_all.map(function(x){

                             if(x.id_vehiculo!=null){

                                  if(x.autorizado==false){

                                     let nombre_geo_detectado='';

                                    if(x.id_geocerca){
                                        nombre_geo_detectado=x.id_geocerca.nombre_geocerca;
                                    }
                                   
                                    me.itemsParadasNo.push({'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,'nombre_conductor':x.id_conductor.nombre_conductor,
                                                            'fecha':x.fecha, 'evento':x.evento,'nombre_geocerca':nombre_geo_detectado,'autorizado':x.autorizado});
                                  }



                          me.itemsOperacion.map(function(y){


                                      if(y.id_ve==x.id_vehiculo._id){

                                     //   console.log('mismo vehiculo');

                                          let fecha_res=new Date(x.fecha);
                                          let fecha_arr=new Date(y.fecha);
                                          //mismo vehiculo.. misma fecha tambien

                                        if( (fecha_res.getDate()==fecha_arr.getDate())
                                              &&(fecha_res.getMonth()==fecha_arr.getMonth())
                                              &&(fecha_res.getFullYear()==fecha_arr.getFullYear())) {

                                           if(x.autorizado==true){
                                             y.totalA=parseInt(y.totalA)+1;

                                           }else{
                                             y.totalN=parseInt(y.totalN)+1;

                                           }

                                         }


                                    }

                                    });

                               }//Si exsite el id de vehiculo

                                });





                               
                              
                              me.autorizadas=[];
                              me.noAuto=[];
                              me.distancias=[];

                              me.totalParadasAuto=0;
                              me.totalParadasNo=0;
                              me.total_distancia=0;


                              console.log('cantidad a validar paradas'+ me.ArrayflotaTrabajo.length);


                              me.ArrayflotaTrabajo.map(function(x){

                                let contador_auto=0;
                                let contado_no=0;
                                let contador_km=0;


                                //obtenemos el detalle de paradas permitidas

                                  me.etiquetas_nombre.push(x.nombre);

                                   me.paradas_globales.map(function(y){

                                     if(y.id_vehiculo!=null){

                                  
                                              if(y.id_vehiculo._id==x._id){

                                                          if(y.autorizado==true){
                                                                contador_auto++;
                                                                me.totalParadasAuto++;

                                                          }else if(y.autorizado==false){
                                                              contado_no++;
                                                              me.totalParadasNo++;
                                                          }

                                              }//vehiculo de interes

                                           }
                                    });

                                  me.ArrayResumen.map(function(z){
                                    if(z.id_vehiculo._id==x._id){
                                          contador_km=parseFloat(contador_km)+parseFloat(z.distancia);
                                          contador_km=parseFloat(contador_km).toFixed(2);

                                          me.total_distancia=parseFloat(me.total_distancia)+parseFloat(z.distancia);
                                          me.total_distancia=parseFloat(me.total_distancia).toFixed(2);
                                    }
                                     
                                  });


                              me.autorizadas.push(contador_auto);
                              me.noAuto.push(contado_no);
                              me.distancias.push(contador_km);


                              });//fin de correr vehiculos



                                   me.seriesData=[{
                                          name: 'Paradas Autorizadas',
                                          type: 'column',
                                          data: me.autorizadas
                                        }, {
                                          name: 'No permitidas',
                                          type: 'column',
                                          data: me.noAuto
                                        }
                                        
                                        /*,
                                        {
                                          name: 'km recorridos',
                                          type: 'column',
                                          data: me.distancias
                                        }
                                        */
                                        
                                        ];

                      //  console.log('nombres de campos tabla rendimiento');

                       // console.log(me.etiquetas_nombre);


                        me.chartOptionsDataAuto={
                             chart: {
                                  type: 'bar',
                                  height: 260
                                },
                                plotOptions: {
                                  bar: {
                                    horizontal: false,
                                    columnWidth: '55%',
                                    endingShape: 'rounded'
                                  },
                                },
                                dataLabels: {
                                  enabled: false
                                },
                                stroke: {
                                  show: true,
                                  width: 2,
                                  colors: ['transparent']
                                },
                                xaxis: {
                                  categories: me.etiquetas_nombre,
                                   labels: {
                                      trim: true,
                                       maxHeight: 65,
                                   }
                                
                                },
                                yaxis: {
                                  title: {
                                    text: ''
                                  }
                                },
                                fill: {
                                  opacity: 1
                                },
                                 legend: {
                                            position: 'top'
                                          },
                                tooltip: {
                                  y: {
                                    formatter: function (val) {
                                      return "" + val + " "
                                    }
                                  }
                                }
                             };





                              me.chartOptionsRalenti={
                                                        chart: {
                                                            type: 'bar',
                                                            height: me.heightRalenti,
                                                              toolbar: {
                                                                show: false,
                                                                },
                                                          },
                                                          plotOptions: {
                                                            bar: {
                                                              horizontal: true,
                                                              columnWidth: '55%',
                                                              endingShape: 'rounded'
                                                            },
                                                          },
                                                          dataLabels: {
                                                            enabled: false
                                                          },
                                                          stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent']
                                                          },
                                                          xaxis: {
                                                            categories: me.etiquetas_nombre,
                                                            labels: {
                                                                trim: true,
                                                                maxHeight: 75,
                                                              }
                                                          },
                                                          yaxis: {
                                                            title: {
                                                              text: ''
                                                            }
                                                          },
                                                          fill: {
                                                            opacity: 1
                                                          },
                                                          tooltip: {
                                                            y: {
                                                              formatter: function (val) {
                                                                return " " + val + ""
                                                              }
                                                            }
                                                          }
                                                        };




                     

                        //----------------------------------------------------------------------
                        //------------------------EXCEOS DE VELOCIDAD---------------------------
                        //--------------------------------------------------------------------
        



                          this.itemsExceso=[];
                          this.numeroExcesos=0;
                          this.seriesExcesos=[{'name':'', 'data':[]}];

                          let label_severidad=[];
                          let datos_local_barra=[];

                          this.vehiculos_excesos=[];
                          this.etiquetas_excesos=[];

                          this.CategoriaGlobalExces=[];


                         // label_severidad.push('Leve');
                         // let diseno=me.ObtenerDisenoSeveridad(0);
                         // me.CategoriaGlobalExces.push({'nombre':'Leve', 'valor':0,'color':diseno.color, 'icono':diseno.icono});
                          //datos_local_barra.push({'name':'Leve', 'data':[]});


                          label_severidad.push('Grave');
                          let diseno=me.ObtenerDisenoSeveridad(1);
                          me.CategoriaGlobalExces.push({'nombre':'Grave', 'valor':0,'color':diseno.color, 'icono':diseno.icono});
                          datos_local_barra.push({'name':'Grave', 'data':[]});


                          label_severidad.push('Critico');
                          diseno=me.ObtenerDisenoSeveridad(2);
                          me.CategoriaGlobalExces.push({'nombre':'Critico', 'valor':0,'color':diseno.color, 'icono':diseno.icono});
                          datos_local_barra.push({'name':'Critico', 'data':[]});




                             me.ArregloExcesos.map(function(x){

                               let estado_ingresar=false;

                                 if(x.id_vehiculo!=null){
                                 
                                        if(me.estado_switch==true){   //añadimos paradas de la flota seleccionada
                                            if(x.id_vehiculo.flota==me.id_flota_vista){
                                                estado_ingresar=true;
                                            }
                                        }else{ //añadimos paradas de la Subflota seleccionada

                                                 

                                              if((x.id_vehiculo.id_subflota_veh)&&(x.id_vehiculo.id_subflota_veh!=undefined)){

                                                
                                                  if(me.id_flota_vista==x.id_vehiculo.id_subflota_veh){
                                                        estado_ingresar=true;
                                                    }
                                                }

                                        }

                              
                                      if(estado_ingresar==true){
                                                  let encontrado=false;

                                                  me.vehiculos_excesos.map(function(y){
                                                    if(y==x.id_vehiculo._id){
                                                      encontrado=true;
                                                    }

                                                  });

                                                  if(encontrado==false){

                                                     // console.log('exceso');
                                                     // console.log(x);
                                                       
                                                        me.vehiculos_excesos.push(x.id_vehiculo._id);
                                                        me.etiquetas_excesos.push(x.id_vehiculo.nombre);

                                                    }


                                                    
                                                   let clasificacion=[];
                                                   let severidad='';

                                                   if((x.id_vehiculo) && (x.id_vehiculo.flota!=null)){

                                                        //buscamos la segmentacion para esa flota de ese evento
                                                        me.arregloFlotas.map(function(b){
                                                              if(me.estado_switch==true){  
                                                                        if(b.nombre_flota==x.id_vehiculo.flota){
                                                                            clasificacion=b.clasificacion_velocidad;
                                                                        }

                                                                }else{
                                                                         //buscamos la segmentacion para esa Subflota de ese evento
                                                                      if((x.id_vehiculo.id_subflota_veh)&&(x.id_vehiculo.id_subflota_veh!=undefined)){
                                                                        if(me.id_flota_vista==x.id_vehiculo.id_subflota_veh){
                                                                              clasificacion=b.clasificacion_velocidad;
                                                                            
                                                                          }
                                                                      }

                                                                  
                                                                }

                                                          });


                                                    

                                                     clasificacion.map(function(a){
                                                    
                                                    if((parseFloat(x.velocidad)  >= parseFloat(a.desde))&&(parseFloat(x.velocidad)  <=  parseFloat(a.hasta))){
                                                      if(x.velocidad){
                                                          severidad=a.nombre;
                                                          x.severidad=severidad;

                                                        
                                                           
                                                         //  console.log('detalle del exceso:'+severidad+'de:'+parseFloat(x.velocidad));
                                                
                                                      }
                                                    }
                                                  });

                                                   }//si los datos de vehiculo son validos


                                                    me.itemsExceso.push(x);
                                                   
                                                  

                                                   

                                        }

                                 }




                                
                             });

                           //  console.log('cantidad de excess:'+me.itemsExceso.length);






      


                            datos_local_barra.map(function(x){

                              

                              me.vehiculos_excesos.map(function(y){

                              

                                 let contador_evento=0;


                                  me.ArregloExcesos.map(function(z){


                                     if(z.id_vehiculo!=null){

                                              if(y==z.id_vehiculo._id){


                                                 let clasificacion=[];

                                                  me.arregloFlotas.map(function(b){

                                                      if(me.estado_switch==true){ 
                                                              if(b.nombre_flota==z.id_vehiculo.flota){
                                                                  clasificacion=b.clasificacion_velocidad;
                                                              }

                                                      }else{

                                                          if((z.id_vehiculo.id_subflota_veh)&&(z.id_vehiculo.id_subflota_veh!=undefined)){
                                                                        if(me.id_flota_vista==z.id_vehiculo.id_subflota_veh){ 
                                                                              clasificacion=b.clasificacion_velocidad;
                                                                          }
                                                                      }

                                                      }
                                                });




                                                    clasificacion.map(function(a){
                                                      
                                                      if((a.nombre==x.name)&&(parseFloat(z.velocidad)  >= parseFloat(a.desde))&&(parseFloat(z.velocidad)  <=  parseFloat(a.hasta))){
                                                        if(z.velocidad){
                                                            contador_evento++;
                                                        }
                                                      }
                                                    });//fin de clasificacion

                                              }//si es mimso vehiculo
                                        }
                                        
                                      }); //fin de excesos


                                       //insertamos en detalle de barras locales valor de unidad

                                      let datos_anterior=x.data;
                                      datos_anterior.push(contador_evento);
                                      x.data=datos_anterior;

                                   

                                     


                                   }); //fin de flota
                              
                              }); //fin de datos de barras


        
                               me.seriesExcesos=datos_local_barra;

                              

                                me.chartOptionsExcesos={
                                      
                                      chart: {
                                        type: 'bar',
                                        height: 240,
                                         width:540,
                                        stacked: true,
                                        toolbar: {
                                              show: false,
                                              },
                                      },
                                        colors: ['#56CA00', '#F44336',  '#DFCB5E', '#303C9B', '#FFFD95','#C6D57E','#ED50F1', '#A8E7E9','#57CC99'],
                     
                                      plotOptions: {
                                        bar: {
                                          horizontal: true,
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                        colors: ['#fff']
                                      },
                                      title: {
                                        text: ''
                                      },
                                      xaxis: {
                                        categories:me.etiquetas_excesos,
                                        labels: {
                                          formatter: function (val) {
                                            return val + ""
                                          }
                                        }
                                      },
                                      yaxis: {
                                        title: {
                                          text: undefined
                                        },
                                      },
                                      tooltip: {
                                        y: {
                                          formatter: function (val) {
                                            return val + ""
                                          }
                                        }
                                      },
                                      fill: {
                                        opacity: 1
                                      }
                                
                                      };


                              



                            me.CategoriaGlobalExces.map(function(x){

                                  let total=0;

                                  datos_local_barra.map(function(y){

                                    if(x.nombre==y.name){
                                      let datos=y.data;

                                      for(let i of datos) total+=i;
                                      x.valor=total;

                                      me.numeroExcesos=total+parseInt(me.numeroExcesos);

                                    }

                                  });



                                });    
                                
                                


                 //----------------------------------------------------------------------------
                //--------------------------INGRESOS COMBUSTIBLE------------------------------
                //----------------------------------------------------------------------------

                this.distancia_global=0;
                this.galones_aprox_global=0;
                this.galones_asignados_global=0;
                this.eficiencia_vehiculos=[];
                this.kmlxgl_vehiculos=[];

                
                this.eficiencia_global=0;

                            

                      me.ArrayIngresos.map(function(x){


                          me.itemsCombustible.map(function(y){

                             if(x.id_vehiculo!=null){

                                  if(x.id_vehiculo._id==y.id_ve){

                                    y.asignacion=parseFloat(y.asignacion)+parseFloat(x.cantidad);
                                    y.costo=parseFloat(y.costo)+parseFloat(x.costo);


                                  }

                             }

                          });



                      });

                      let eficiencia_promedio_global=[];

                      let arreglo_distancia=[];
                      let arreglo_estimado=[];
                      let arreglo_asignacion=[];
                      let arreglo_costo=[];

                     

                     


                        me.ArrayflotaTrabajo.map(function(x){

                              let total_distance=0;
                              let total_gl_estimado=0;

                              let total_gl_asignados=0;

                              let gasto_total=0;

                               let arreglo_eficiencia=[];

                               let arreglo_kmxgl=[];

                              

                              me.ArrayResumen.map(function(z){
                                            if(z.id_vehiculo._id==x._id){
                                              
                                                  total_distance=parseFloat(total_distance)+parseFloat(z.distancia);
                                                  total_distance=parseFloat(total_distance).toFixed(2);
                                                

                                                    if((z.consumo!="N/A")&&(parseFloat(z.consumo) >0)){

                                                        total_gl_estimado=parseFloat(total_gl_estimado)+parseFloat(z.consumo);
                                                        total_gl_estimado=parseFloat(total_gl_estimado).toFixed(2);
                                                  }


                                            }
                                            
                                          }); //fin de resumenes



                                me.ArrayIngresos.map(function(y){

                                   if(y.id_comb_vehiculo!=null){

                                  
                                      if(y.id_comb_vehiculo._id==x._id){
                                        
                                                      total_gl_asignados=parseFloat(total_gl_asignados)+parseFloat(y.cantidad);
                                                      total_gl_asignados=parseFloat(total_gl_asignados).toFixed(2);

                                                      gasto_total=parseFloat(gasto_total)+ parseFloat(y.costo);

                                                      console.log('cantidad:'+y.cantidad+',eficienta:'+y.eficiencia);

                                                       if(parseFloat(y.eficiencia)>0){
                                                          arreglo_eficiencia.push(parseFloat(y.eficiencia).toFixed(2));
                                                          console.log('eficiencia:'+parseFloat(y.eficiencia).toFixed(2));
                                                        }

                                                        if(parseFloat(y.kmxgl)>0){
                                                        //  console.log('valor km*gl:'+y.kmxgl);
                                                          arreglo_kmxgl.push(parseFloat(y.kmxgl).toFixed(2));
                                                        }
                                      }

                                }



                                });  
                                
                                arreglo_distancia.push(total_distance);
                                arreglo_estimado.push(total_gl_estimado);
                                arreglo_asignacion.push(total_gl_asignados);
                                arreglo_costo.push(gasto_total);

                                //sacamos la media de eficiencia



                                  me.distancia_global=parseFloat(total_distance)+parseFloat(me.distancia_global);
                                  me.distancia_global=parseFloat(me.distancia_global).toFixed(2);


                                  me.galones_aprox_global=parseFloat(total_gl_estimado)+parseFloat(me.galones_aprox_global);
                                  me.galones_aprox_global=parseFloat(me.galones_aprox_global).toFixed(2);


                                  me.galones_asignados_global=parseFloat(total_gl_asignados)+parseFloat(me.galones_asignados_global);
                                  me.galones_asignados_global=parseFloat(me.galones_asignados_global).toFixed(2);


                                    if(arreglo_eficiencia.length >0){
                                            me.eficiencia_vehiculos.push(me.calculateAverage(arreglo_eficiencia));
                                           eficiencia_promedio_global.push(me.calculateAverage(arreglo_eficiencia));
                                  }else{
                                     me.eficiencia_vehiculos.push(0);
                                   }


                                   if(arreglo_kmxgl.length >0){
                                        me.kmlxgl_vehiculos.push(me.calculateAverage(arreglo_kmxgl));
                                   }else{
                                       me.kmlxgl_vehiculos.push(0);
                                   }

                          }); //fin de vehiculos


                          if(eficiencia_promedio_global.length >0){
                                   me.eficiencia_global=me.calculateAverage(eficiencia_promedio_global);
                              }




                        //  console.log('metiendo datos en grafica');
                        //  console.log(me.etiquetas_nombre);

                      
                      me.chartOptionsCombustible={
                                              chart: {
                                                  type: 'bar',
                                                  height: 230,
                                                  toolbar:{
                                                        show: false,
                                                    }
                                                },
                                                plotOptions: {
                                                  bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                  },
                                                },
                                                dataLabels: {
                                                  enabled: false
                                                },
                                                stroke: {
                                                  show: true,
                                                  width: 2,
                                                  colors: ['transparent']
                                                },
                                                xaxis: {
                                                  categories:  me.etiquetas_nombre,
                                                  labels: {
                                                        trim: true,
                                                        maxHeight: 70,
                                                      }
                                                },
                                                yaxis: {
                                                  title: {
                                                    text: ''
                                                  }
                                                },
                                                legend: { position: 'top'},
                                                fill: {
                                                  opacity: 1
                                                },
                                                tooltip: {
                                                  y: {
                                                    formatter: function (val) {
                                                      return " " + val + ""
                                                    }
                                                  }
                                                }
                                            };



                    

                           me.seriesCombustible= [{
                                                name: 'Distancia km',
                                                data: arreglo_distancia
                                              },{
                                                name: 'Registro gasto Gl',
                                                data: arreglo_asignacion
                                              },
                                               {
                                                name: 'Eficiencia',
                                                data: me.eficiencia_vehiculos
                                              },
                                              {
                                                 name: 'Km/Gl',
                                                data: me.kmlxgl_vehiculos

                                              }];




                    //---------------------------------------------------------------------------
                      //---------------------------MANTENIMIENTOS----------------------------------
                      //---------------------------------------------------------------------------




                   me.mantt_globales.map(function(x){


                        if(me.estado_switch==true){  //ver flotas

                            if(x.id_vehiculo_mant.flota==me.id_flota_vista){
                                 me.ArregloMantenimientos.push(x);
                             }

                         }else{  //ver subflotas

                              if((x.id_vehiculo_mant.id_subflota_veh)&&(x.id_vehiculo_mant.id_subflota_veh!=undefined)){
                                    if(me.id_flota_vista==x.id_vehiculo_mant.id_subflota_veh){
                                          me.ArregloMantenimientos.push(x);
                                      }
                                  }
                          }

                       

                      });


                     
                      me.numeroMattos=me.ArregloMantenimientos.length;

                      me.ArregloMantenimientos.map(function(x){

                            if(x.id_tipo_mant.clasificacion=='Aceite'){ me.numeroMattoAceite++;}
                            if(x.id_tipo_mant.clasificacion=='llantas'){me.numeroMattoLlantas++;}
                            if(x.id_tipo_mant.clasificacion=='Frenos'){ me.numeroMattoFreno++;}

                      });


                        //----------------------------------
                        //------DATOS DE BOTONES------------
                        //----------------------------------




                                      me.numeroBotones=0;

                                      me.ArrayBotones.map(function(x){


                                          if(me.estado_switch==true){  //ver flotas

                                                  if(x.id_vehiculo.flota==me.id_flota_vista){
                                                            if(x.editado!=''){
                                                                    me.numeroBotonesEditados++;
                                                                }

                                                      me.numeroBotones++;
                                                  }

                                           }else{  //ver subflotas

                                                        if((x.id_vehiculo.id_subflota_veh)&&(x.id_vehiculo.id_subflota_veh!=undefined)){
                                                                if(me.id_flota_vista==x.id_vehiculo.id_subflota_veh){

                                                                    if(x.editado!=''){
                                                                            me.numeroBotonesEditados++;
                                                                        }

                                                                          me.numeroBotones++;
                                                                  }
                                                              }
                                          
                                              }

                                         

                                      });
















                  me.ocultarLoading();

              



          },//fin de funcion de cambio de switch












        CalcularFechaHoy(){
          let fecha=new Date();
           fecha.toLocaleString('en-US', { timeZone: 'America/Managua'});
          let mes=fecha.getMonth()+1;
          if(mes<10)mes='0'+mes;
          let dia=fecha.getDate();
          if(dia<10)dia='0'+dia;
          let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

          return(textofecha);
        },



         cerrar(){
              this.dialogExit=false;
              localStorage.removeItem('usuario');
              localStorage.removeItem('token');
              
              this.salir();
            },

        CambiarVistaPais(){

                this.$router.push('/dashpais'); 

         },


        convertDurationtoSeconds(duration){
          const [hours, minutes, seconds] = duration.split(':');
          return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
        },


        openGoogleMap(item){
            const urlSuffix = item.latitud +','+ item.longitud;
            window.open(
              "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
              "_blank"
            );
        },

      getDate(evaluar) {
        let date = new Date(evaluar),
        year = date.getFullYear(),
        month = (date.getMonth() + 1).toString(),
        formatedMonth = (month.length === 1) ? ("0" + month) : month,
        day = date.getDate().toString(),
        formatedDay = (day.length === 1) ? ("0" + day) : day,
        hour = date.getHours().toString(),
        formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
        minute = date.getMinutes().toString(),
        formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
        second = date.getSeconds().toString(),
        formatedSecond = (second.length === 1) ? ("0" + second) : second;
      return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
      },


       ConvertirFecha(fecha_parametro){

        let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();

        return(texto);

      },

         ConvertirDia(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },

        CambiarImgUrl(picture){
            picture=picture.substring(0,picture.length - 5);
            if(picture.slice(-1)!='/'){
                    picture=picture.substring(0,picture.length - 1);
                }
                return (picture+'/E.png');
      },

      evaluarVencimineto(fechaVence){

  let icon='';

  //fecha recibida + 1 mes

    let fecha_hoy=new Date();
    let fecha_mes_sig=new Date();

    if(fecha_hoy.getMonth()>11){
       
         fecha_mes_sig.setMonth(0);
         fecha_mes_sig.setFullYear(fecha_hoy.getFullYear()+1);
        // console.log('mes siguiente, fecha:'+fecha_mes_sig);
    }else{

         fecha_mes_sig.setMonth(fecha_hoy.getMonth()+1);
       //  console.log('mes siguiente, fecha:'+fecha_mes_sig);
    }


    let fecha_recibida=new Date(fechaVence);

      if(fecha_mes_sig < fecha_recibida){
      //  console.log('bien');

           icon='done';

      }else{

     // console.log('alerta falta menos de 30 dias');
      
          icon='error';

      }
 

        return(icon);


      },

        getColor (item) {
 
              if(item.editado !=''){
                      return 'pink';
              }else{
                    return 'red';
              }
                
      },

      evaluarSeveridad(item){

        let texto_severidad='';

          let clasificacion=[];

          let me=this;

                               me.configPaises.map(function(x){

                                 if(x._id==me.id_pais_activo){
                                     clasificacion=x.clasificacion_velocidad;

                                       clasificacion.map(function(a){
                                                      
                                          if((item.velocidad  >= a.desde)&&(item.velocidad  <=  a.hasta)){
                                               texto_severidad=a.nombre;
                                          }
                                        });//fin de clasificacion
                                 }//si el pais  esta activo

                               });

               return(texto_severidad);

      },


      
       editItemBoton (item) {

          this.editedItem = Object.assign({}, item);

          this.editedItem.nombre=item.id_vehiculo.nombre;
           this.editedItem.placa=item.id_vehiculo.placa;

         if(this.editedItem.fecha){this.editedItem.fecha=this.getDate(this.editedItem.fecha);}
         this.dialog = true;


       },

        save () {

        if(this.editedItem.observacion!=''){

         
              //edicion de evento para ingreso de comentario
  
                    let me=this;
                    let config={headers:{token:me.token}}; 
                    me.mostrarLoading({titulo:'Accediendo a datos'});
                    let id=this.editedItem._id;


                            axios.put(`eventos/${id}`,
                              { 
                                'observacion': me.editedItem.observacion,
                                'editado': me.usuario.nombre_usuario+', a las: '+me.getDate(new Date())
                              },
                              config

                                ).then(response=>{
                              
                               console.log(response.data);

                                 me.listarBotones();
                                 me.close();
                                
                          }).catch(e=>{
                                console.log(e)
                          });

          
      }else{
          alert('debes ingresar observacion');
      }


      },

         close () {
          this.dialog = false
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          
        })
      },

         calculateAverage(array) {
                let total = 0;
                let count = 0;
                let average=0;
                for (let iterar=0; iterar < array.length; iterar++){
                      total =parseFloat(array[iterar])+parseFloat(total);
                      count++;
                  }

                  console.log('total calculate average:'+total+', cantidad:'+count);

                  average=total / count;
                return parseFloat(average).toFixed(2);
            },







    }
}
</script>

<style>

.my-notification {
  margin-left:55px;
  padding: 10px;
  font-size: 12px;
}

</style>

